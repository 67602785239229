import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classes from './Contract.module.css';
import Button from '../../Components/Commons/FormComponent/Button';
import SecondSession from '../../Components/HomePage/SecondSession';
import * as clientCredentialApi from '../../Axios/Call/ClientCredential';

const Contract = () => {
    const {id} = useParams();
    const [loadingApi, setLoadingApi] =  useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState({});
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoadingApi(true);
        clientCredentialApi.getContracts().then(response => {
            setContracts(response.data);
            setLoadingApi(false);
        }).catch(error => {
            setLoadingApi(false);
            setErrorApi(true);
        })

        clientCredentialApi.getContract(id).then(response => {
            setContract(response.data);
        }).catch(error => {
            setErrorApi(true);
        })
    },[])

    return(<>
        <Row style={{background: 'linear-gradient(#0D2E3D, #0B2430)', paddingBottom: 30, textAlign: "center"}}>
             <Col style={{minHeight: 200, paddingLeft: 60, paddingTop: 30}} xs={12}>
                <Row style={{height: '60%', width: '60%'}}><Col className='text-left' ><h1 className={classes.headerTitle}>{contract.name}</h1></Col></Row>
                <Row><Col className='text-left'><span className={classes.priceTime}>price - time</span></Col></Row>
             </Col>
             <Col>
                <Row><Col><span>Contratto per prestito tra privati</span></Col></Row>
                <Row><Col><span>Soddisfatto o rimborsato</span></Col></Row>
                <Row><Col><Button>Compra il documento</Button></Col></Row>
             </Col>
         </Row>
         <Row style={{marginTop: '2rem', marginLeft: 20, marginRight: 20}}>
             <Col>
                <Row style={{ border: '1px solid red'}}>
                    <Col style={{padding: 20}}>
                        <span>{contract.description}</span>
                    </Col>
                </Row>
                <SecondSession contracts={contracts.slice(2, 8)} loadingApi={loadingApi} errorApi={errorApi}/>
             </Col>
         </Row>
    </>)
}

export default Contract;