import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {FacebookIcon, InstagramIcon} from '../../../../icons';
import Logo from '../../../../Assets/Images/logo.png';
import LogoMobile from '../../../../Assets/Images/logo_mobile.png'
import classes from './Footer.module.css';
import {useSelector, useDispatch} from 'react-redux';
import * as actionCreator from '../../../../store/action/index';

const Footer = () => {
    const categoryContract = useSelector(state => state.categoryContract.categoryContract);

    return (<>
    <div style={{width: '100%', marginTop: '4rem'}}>
        <Col>
            <Row style={{background: 'linear-gradient(#0D2E3D, #0B2430', paddingBottom: 80}}>
                <Col>
                    <Row>
                        <Col className='text-left' lg={8}><Image src={Logo} /></Col>
                        <Col className='text-right my-auto'>
                            <FacebookIcon className={classes.iconStyle} style={{fill: 'white'}}/>
                            <span className={classes.socialTextStyle}>FACEBOOK</span>
                        </Col>
                        <Col className='text-center my-auto'>
                            <InstagramIcon className={classes.iconStyle} style={{fill: 'white'}}/>
                            <span className={classes.socialTextStyle}>INSTRAGRAM</span>
                        </Col>
                    </Row>
                    <Row>
                        {categoryContract.map( category => {
                            return(
                                <>
                                <Col className='text-center' xs={12} md={6} lg={3}>
                                    <span className={classes.settingTextStyle}>{category.name}</span>
                                </Col>
                                {category.contracts.map(contract => {      
                                    return(
                                        <Row>
                                        <Col className='text-center' xs={12} md={6} lg={3}>
                                            <span className={classes.contractTextStyle}>{contract.title}</span>
                                        </Col>
                                        </Row>
                                        )
                                    })}
                                </>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
            <Row style={{backgroundColor: 'black', padding: 10, paddingLeft: 40}}>
                <Col xs={12} md={8}>
                    <Row><Col>
                            <span className={classes.bottomTextStyle}>Copyright © 2020 Contratto Fast. Tutti i diritti sono riservati. PIVA: IT 00000000000</span>
                        </Col></Row>
                        <Row><Col>
                            <span className={classes.bottomTextStyle}>Images offered by freepik.com</span>
                        </Col></Row>
                </Col>
                <Col className='text-right my-auto'>
                    <span className={classes.bottomTextStyle} style={{fontSize: 12}}>Privacy Policy</span>
                </Col>
                <Col className='text-center my-auto'>
                    <span className={classes.bottomTextStyle} style={{fontSize: 12}}>Termini e condizioni</span>
                </Col>
            </Row>
        </Col>
    </div>
    </>)
}

export default Footer;