import React, {useState, useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import classes from './HomePage.module.css'
import ContractCard from './ContractCard';
import {Link} from 'react-router-dom';
import {OrangeBox,SittedGirl, BlueOrangeBox} from '../../icons';
import LoadingButton from '../Commons/LoadingButton/LoadingButton';
import * as clientCredentailApi from '../../Axios/Call/ClientCredential';
import {useSelector } from 'react-redux';

const SecondSession = (props) => {
    const categoryContract = useSelector(state => state.categoryContract.categoryContract);
    const [choosenCategory, setChoosenCategory] = useState(1);

    const onSetChoosenCategory = (categoryId) => {
        setChoosenCategory(categoryId);
    }
    return(<>
        <Row style={{marginTop: '4rem'}}>
            <Col xs={12} lg={5}>
                <Row><Col><span className={classes.headerSecondSession}>Contratti piu' richiesti</span></Col></Row>
                <Row><Col><span className={classes.subHeaderSecondSession}>Guarda tra i contratti più richiesti o clicca una delle nostre categorie per visualizzare determinati contratti</span></Col></Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col>
                        <OrangeBox className={classes.orangeBoxStyleSecondSession} style={{ width:"771.896", height:"771.896"}}/>
                        <Card style={{zIndex: 10}}>
                            {categoryContract.map(category => {
                                return(
                                    <>
                                    <Row key={category.id} style={{marginTop: '1rem'}}>
                                        <Col className='text-center'>
                                            <span onClick={() => onSetChoosenCategory(category.id)} className={classes.categoryStyle}>{category.name}</span>
                                        </Col>
                                    </Row>
                                    {category.id == choosenCategory ? 
                                        <Row className={classes.scrollbar}>
                                            <Col >
                                                {category.contracts.map(contract => {
                                                    return(
                                                        <Row key={contract.id}>
                                                            <Col >
                                                                <Link to={`/contratto/${contract.id}`}><span className={classes.contractStyle}>{contract.name}</span></Link>
                                                            </Col>
                                                        </Row>
                                                    
                                                    )
                                                })}
                                            </Col>
                                         </Row>
                                    : null}
                                    </>
                                )
                            })}
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col style={{paddingLeft: 50,}}>
                <Row style={{marginTop: '2rem'}}>
                    <Row > 
                        <Col style={{zIndex: 4}}>
                            <SittedGirl className={classes.sittedGirlStyle}/>
                            <div style={{ position: 'relative'}}>
                                <BlueOrangeBox className={classes.blueOrangeBoxStyleSecondSession} style={{ width:"671.896", height:"671.896"}}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    { props.loadingApi ? <LoadingButton /> :
                        props.contracts.map(contract => {
                            return(
                                <Col xs={12} md={6} style={{marginTop: '1rem', zIndex: 10}} className={classes.padding0}>
                                    <ContractCard key={contract.id} contract={contract} />
                                </Col>
                            )
                        })}
                    </Row>
                </Row>
            </Col>
        </Row>
    </>)
}

export default SecondSession;