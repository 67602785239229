import React, {useEffect, useState, Fragment} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Redirect, useHistory} from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './ModalSimple.module.css';
import Lottie from 'react-lottie';
import avocadoBro from '../../../Assets/Animation/avocad-bros.json';
import busyData from '../../../Assets/Animation/busy.json';
import cocomeroData from '../../../Assets/Animation/cocomero.json';
import loader from '../../../Assets/Animation/loader.json';



const ModalSimple = (props) => {
    const history = useHistory();

    let animationData = avocadoBro;
    if(props.animationType === 'busy'){
        animationData = busyData;
    }
    if(props.animationData === 'loader'){
        animationData = loader
    }
    const defualtOptions = {
        loop:true,
        autoplay: true,
        animationData: animationData,
        renderSettings: {
            preserveAspectRation: "xMidYmid slice"
        }
    }

    const onHideHandler = () => {
        history.push('/');
    } 

    const titleSize = props.title.length > 10 ? '2rem' : '3.1rem' 

    return(
        <Fragment>
            <Modal show={true}  onHide={props.onHideCustomeHandler ? props.onHideCustomeHandler : onHideHandler}>
                <Modal.Header closeButton>
                    <Row>
                        <Col>
                            <span style={{ fontSize: titleSize, color: props.color }} className={classes.ModalTitile}>{props.title}</span>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col className="text-center">
                            <span className={classes.ModalDescription}>{props.description}</span>
                        </Col>
                    </Row>
                    { props.noAnimation ? null :
                        <Row>
                            <Col>
                                <Lottie options={defualtOptions} height={350} width={350} />
                            </Col>
                        </Row>
                    }
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}


export default ModalSimple;