import axios from 'axios';

export const clientCredentialAxios = axios.create({
    baseURL:axios.defaults.baseURL = 'https://api.contrattofast.it/'
});

clientCredentialAxios.defaults.headers.common['Accept'] = 'application/json';

export const authApiAxios = axios.create({
    baseURL:axios.defaults.baseURL = 'https://api.contrattofast.it/'
})

authApiAxios.defaults.headers.common['Accept'] = 'application/json';
authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
