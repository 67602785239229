import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Logo from '../../../../Assets/Images/logo.png';
import LogoMobile from '../../../../Assets/Images/logo_mobile.png'
import Image from 'react-bootstrap/Image';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import classes from './Navbar.module.css';
import Button from 'react-bootstrap/Button';
import * as actionCreator from '../../../../store/action/index';
import { useWindowWidth } from '@react-hook/window-size'; 


const NavbarCommon = () => {
    const userData = useSelector( state => state.user.userData);
    const screenWidth = useWindowWidth();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const onClickLogoutHandler = () => {
        dispatch(actionCreator.logoutHandler());
        history.push('/')
    }

    let menuButton = null;
    if(!Object.keys(userData).length){
        menuButton = <Nav.Link as ={Link} to='/login' eventKey="login" >
            <span className={location.pathname == '/login' ? classes.nameStyleSelected :classes.nameStyle}>LOGIN</span>
        </Nav.Link>
    }else{
        menuButton = <><Nav.Link as={Link} to='/profilo' eventKey="profile" >
            <span className={location.pathname == '/profilo' ? classes.nameStyleSelected : classes.nameStyle}>PROFILO</span>
        </Nav.Link>
        <Nav.Link as={Link} onClick={() => onClickLogoutHandler()} eventKey="logout" >
            <span className={classes.nameStyle}>LOGOUT</span>
        </Nav.Link>
        </>
    }
    return(<>
        <Navbar collapseOnSelect expand="lg" variant="light"  >
            <Navbar.Brand as={Link} to="/"><Image style={{width: screenWidth > 720 ? '100%' : '50%', marginLeft: screenWidth > 720 ? 0 : -80}} src={ screenWidth > 720 ? Logo : LogoMobile} alt='Workoutly-logo' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className={"mr-auto text-center "+classes.navStyle}>
                    <Nav.Link as={Link} to='/contratti' eventKey="contracts" >
                        <span className={location.pathname == '/contratti' ? classes.nameStyleSelected : classes.nameStyle}>CONTRATTI</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to='/contatti' eventKey="contacts" >
                        <span className={location.pathname == '/contatti' ? classes.nameStyleSelected :classes.nameStyle}>CONTATTI</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to='/faq' eventKey="faq" >
                        <span className={location.pathname == '/faq' ? classes.nameStyleSelected :classes.nameStyle}>FAQ</span>
                    </Nav.Link>
                    {menuButton}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </>)
}

export default NavbarCommon;