import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const UploadedLogos = () => {
    const [logos, setLogos] = useState([{name: 'Logo azienda', picture: 'https://www.manciomarket.com/static/media/logonew.8336ca59.png' },
    {name: 'Logo azienda', picture: 'https://www.manciomarket.com/static/media/logonew.8336ca59.png' },
    {name: 'Logo azienda', picture: 'https://www.manciomarket.com/static/media/logonew.8336ca59.png' },
    {name: 'Logo azienda', picture: 'https://www.manciomarket.com/static/media/logonew.8336ca59.png' },
    {name: 'Logo azienda', picture: 'https://www.manciomarket.com/static/media/logonew.8336ca59.png' },
    {name: 'Logo azienda', picture: 'https://www.manciomarket.com/static/media/logonew.8336ca59.png' },
    {name: 'Logo azienda', picture: 'https://www.manciomarket.com/static/media/logonew.8336ca59.png' }])

    return(<>
        <Row>
            {logos.map(logo => {
                return(
                    <Col xs={12} md={6} lg={4} style={{padding: 30}}>
                        <Row>
                            <Col><span>{logo.name}</span></Col>
                        </Row>
                        <Row style={{border: '1px solid black'}}>
                            <Col style={{padding: 20}}>
                                <Image style={{ width: '90%'}} src={logo.picture} />
                            </Col>
                        </Row>
                    </Col>
                )
            })}
        </Row>
    </>)
}

export default UploadedLogos