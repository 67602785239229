import React from 'react';
//--------------------------- Lottie ---------------------------
import Lottie from 'react-lottie';
import loader from '../../../Assets/Animation/loader.json';
import error from '../../../Assets/Animation/error.json';


const LoadingButton = (props) => {

    let animationData = loader;
    if(props.animationData == 'error'){
        animationData = error;
    }

    const defualtOptions = {
        loop:true,
        autoplay: true,
        animationData: animationData,
        renderSettings: {
            preserveAspectRation: "xMidYmid slice"
        }
    }

    return(
        <Lottie options={defualtOptions} height={props.animationData == 'error' ? 120 : 120} width={props.animationData == 'error' ? 120 : 200} />
    );
}



export default LoadingButton