import * as actionTypes from '../action/actionsTypes';
import {updateObject} from '../utility';

const initialState = {
    userData: {},
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.STORE_USER_DATA:
            return updateObject(state, {userData: action.userData})
        case actionTypes.LOGOUT_USER:
            return updateObject(state, {userData: {}});
        }

    return state;
}

export default reducer