import React, {useEffect, useState} from 'react'
//------------------ Bootstrap import --------------------------
import CookieConsent from "react-cookie-consent";
import {Link, useHistory} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { useDispatch, useSelector} from 'react-redux';
import * as actionCreator from '../store/action/index';
import ModalSimple from '../Components/Commons/ModalSimple/ModalSimple';
import Navbar from '../Components/Commons/Hoc/Navbar/Navbar';
import Footer from '../Components/Commons/Hoc/Footer/Footer';



const LayoutGeneral = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userData = useSelector((state) => state.user.userData);
    const categoryContract = useSelector(state => state.categoryContract.categoryContract);
    useEffect(() => {
        if(!Object.keys(userData).length && localStorage.getItem('token')){
            dispatch(actionCreator.storeUserData());
        }
    
        if(!categoryContract.length){
            dispatch(actionCreator.storeCategoryContract());
        }
    },[]);

    const onHideCustomeHandler = () => {
        dispatch(actionCreator.logoutHandler());
        history.push('/')
    }

    return(
        <>
        { /* Object.keys(userData).length && !userData.email_verified_at ? 
            <ModalSimple title={'Conferma email'} description='Conferma la tua email per poter procedere' 
                onHideCustomeHandler={onHideCustomeHandler}
            /> : null */}
            <Navbar />
            <Container fluid>
                {props.children}
            </Container>
            <Footer />
            <CookieConsent
                buttonText="Accetto"
            >
                Il sito utilizza i cookie, accettando o proseguendo nel sito accetti la <Link to='/cookie-policy'>cookie privacy policy</Link>
            </CookieConsent> 
        </>
    );
}

export default LayoutGeneral;
