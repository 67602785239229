import React, { useEffect } from 'react';
import './App.css';
//------------------------- Component Import ----------------------------------
import LayoutGeneral from '../Hoc/LayoutGeneral';
import LayoutUser from '../Hoc/LayoutUser';
import HomePage from './HomePage/HomePage';
import Login from './Login/Login';
import Registration from './Registration/Registraion';
import Faq from './Faq/Faq';
import Contact from './Contact/Contact';
import Contracts from './Contracts/Contracts';
import Contract from './Contract/Contract';
import Profile from './Profile/Profile';
import Billing from './Billing/Billing';
import ProblemForm from './ProblemForm/ProblemForm';
import UploadedLogos from './UploadedLogos/UploadedLogos';
import UploadLogo from './UploadLogo/UploadLogo';

//--------------------------  Route Import -----------------------------------
import {Redirect, Route, Router as BrowserRouter, Switch, useHistory} from 'react-router-dom';
import PastBilling from './PastBilling/PastBilling';
import ResetPassword from './ResetPassword/ResetPassword';
import MyContract from './MyContract/MyContract';

// this is kept in sync by actions signIn and signOut
const isAuthenticated = () => localStorage.getItem('token');
const PublicRoute = props => <Route {...props} />;
const PrivateRoute = (props) => isAuthenticated() ? <Route {...props} /> : <Redirect to={'/'}/>;


const App = () => {
  const history = useHistory();

  //------------------------------------ Rendering ----------------------------------------------
    return (
      <div className= 'App'>
        <BrowserRouter history={history} basename={'/'}>
        <Switch>
          <PublicRoute path="/" exact render={() => <LayoutGeneral page={'login'}><HomePage /></LayoutGeneral>}  />
          <PublicRoute path="/login" exact render={() => <LayoutGeneral page={'login'}><Login /></LayoutGeneral>}  />
          <PublicRoute path="/registrazione" exact render={() => <LayoutGeneral page={'registrazione'}><Registration /></LayoutGeneral>}  />
          <PublicRoute path="/faq" exact render={() => <LayoutGeneral page={'faq'}><Faq /></LayoutGeneral>}  />
          <PublicRoute path="/contatti" exact render={() => <LayoutGeneral page={'contact'}><Contact /></LayoutGeneral>}  />
          <PublicRoute path="/contratti" exact render={() => <LayoutGeneral page={'contracts'}><Contracts /></LayoutGeneral>}  />
          <PublicRoute path="/contratto/:id" exact render={() => <LayoutGeneral page={'contract'}><Contract /></LayoutGeneral>} />
          <PublicRoute path="/reset-password/:token" exact render={() => <LayoutGeneral page={'reset-password'}><ResetPassword /></LayoutGeneral>} />
          <PrivateRoute path="/profilo" exact render={() => <LayoutGeneral page={'profile'}><LayoutUser ><Profile /></LayoutUser></LayoutGeneral>}  />
          <PrivateRoute path="/pagamento" exact render={() => <LayoutGeneral page={'billing'}><LayoutUser ><Billing /></LayoutUser></LayoutGeneral>}  />
          <PrivateRoute path="/riepilogo-pagamenti" exact render={() => <LayoutGeneral page={'past-billing'}><LayoutUser ><PastBilling /></LayoutUser></LayoutGeneral>}  />
          <PrivateRoute path="/logo" exact render={() => <LayoutGeneral page={'logo'}><LayoutUser ><UploadLogo /></LayoutUser></LayoutGeneral>}  />
          <PrivateRoute path="/archivio-loghi" exact render={() => <LayoutGeneral page={'archive-logo'}><LayoutUser ><UploadedLogos /></LayoutUser></LayoutGeneral>}  />
          <PrivateRoute path="/segnala-problema" exact render={() => <LayoutGeneral page={'problem'}><LayoutUser ><ProblemForm /></LayoutUser></LayoutGeneral>}  />
          <PrivateRoute path="/i-miei-atti" exact render={() => <LayoutGeneral page={'problem'}><LayoutUser noMenu ><MyContract /></LayoutUser></LayoutGeneral>}  />
        </Switch>
        </BrowserRouter>
      </div>
    );
  }


export default App;
