import React, {useState} from 'react';
//-------------- Boostrap ------------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
//-------------- Components ----------------
import TextBox from '../../Components/Commons/FormComponent/TextBox';
import Button from '../../Components/Commons/FormComponent/Button';
import AlertGeneral from '../../Components/Commons/AlertGeneral/AlertGeneral';
import LoadingButton from '../../Components/Commons/LoadingButton/LoadingButton';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import {Grid, OrangeBoxDot} from '../../icons';
import * as clientCredentailApi from '../../Axios/Call/ClientCredential';
//-------------- Style ---------------------
import classes from './Contact.module.css';

const Contact = () => {
    const [errorSuggestContract, setErrorSuggestContract ] = useState(false);
    const [errorContact, setErrorContact] = useState(false);
    const [loadingSuggestContract, setLoadingSuggestContract] = useState(false);
    const [loadingContact, setLoadingContact] = useState(false);
    const [successSuggestContract, setSuccessSuggestContract] = useState(false);
    const [successContact, setSuccessContact] = useState(false);

    const onClickContractHandler = (data) => {
        setLoadingSuggestContract(true);
        clientCredentailApi.contractSuggestion(data).then(response => {
            setLoadingSuggestContract(false);
            setSuccessSuggestContract(true);
        }).catch(error => {
            setLoadingSuggestContract(false);
            setErrorSuggestContract(true);
        })
    }

    const onClickContactHandler = (data) => {
        setLoadingContact(true);
        clientCredentailApi.sendContact(data).then(response => {
            setLoadingContact(false);
            setSuccessContact(true);
        }).catch(error => {
            setLoadingContact(false);
            setErrorContact(true);
        })
    }

    const emailValidator = (value) => value.trim().match(/^.+@.+$/) || 'Email non valida';

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const { register: register2, errors: errors2, handleSubmit: handleSubmit2 } = useForm({
    });

    return(<>
        {errorSuggestContract ? <AlertGeneral setTrigger={setErrorSuggestContract} closeAlone description='Errore nel form' color='danger' /> : null}
        {errorContact ? <AlertGeneral setTrigger={setErrorContact} closeAlone description='Errore nel form' color='danger' /> : null}
        {successContact ? <AlertGeneral setTrigger={setSuccessContact} closeAlone description='FOrm inviato con successo' color='success' /> : null}
        {successSuggestContract ? <AlertGeneral setTrigger={setSuccessSuggestContract} closeAlone description='FOrm inviato con successo' color='success' /> : null}
        <div className={classes.divPadding}>
        <Row>
            <Col xs={12} xl={4}>
                <p className={classes.textHeader}>VUOI SEGNALARCI UN ATTO CHE TI SERVE?</p>
                <span className={classes.textSubHeader}>Richiedilo qui e lasciaci la tua mail, ti scriveremo appena è disponibile</span>
            </Col>
            <Col>
                <Card style={{backgroundColor: '#0D2E3D', padding: 30 }} >
                    <Form key={1} onSubmit={handleSubmit(onClickContractHandler)} >
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'act'}
                            error={ errors.act ?  'Campo richiesto' : ''} inputStyle={{width: '60%'}} offset={0} span={12} labelName={'Atto / Contratto che vorresti'}
                            labelStyle={{textTransform: 'uppercase'}}
                        />
                        <TextBox ref={register({ validate: emailValidator })} placeHolder={'Scrivi qui'} type={'email'} name={'email'}
                            error={ errors.email ?  errors.email.message : ''} inputStyle={{width: '50%'}} 
                            labelStyle={{textTransform: 'uppercase', marginTop: 20}}
                            offset={0} span={12} labelName={'Email'}
                        />
                        <Row style={{marginTop: '2.5rem', paddingLeft: 20}}><Col>
                            <Button >Invia</Button>
                        </Col></Row>
                    </Form>
                </Card>
            </Col>
        </Row>
        <Row style={{marginTop: '4rem'}}>
            <Col xs={12} xl={4}>
                <p className={classes.textHeader}>PER ALTRE INFORMAZIONI O RICHIESTE</p>
                <span className={classes.textSubHeader}>Compila questo breve format ti scriveremo appena è disponibile</span>
            </Col>
            <Col>
                <Grid style={{ position: 'absolute', width: 800, right: '6%', top: '20%', }}/>
                <Card style={{backgroundColor: '#0D2E3D', padding: 30, zIndex: 10 }} >
                    <Form key={2} onSubmit={handleSubmit2(onClickContactHandler)} >
                        <Row>
                            <Col xs={12} lg={6}>
                                <TextBox ref={register2({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'name'}
                                    error={ errors2.name ?  'Campo richiesto' : ''} inputStyle={{width: '80%'}} offset={0} span={12} labelName={'Name'}
                                    labelStyle={{textTransform: 'uppercase'}}
                                />
                            </Col>
                            <Col>
                                <TextBox ref={register2({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'surname'}
                                    error={ errors2.surname ?  'Campo richiesto' : ''} inputStyle={{width: '80%'}} offset={0} span={12} labelName={'Cognome'}
                                    labelStyle={{textTransform: 'uppercase'}}
                                />
                            </Col>
                        </Row>
                        <TextBox ref={register2({ validate: emailValidator })} placeHolder={'Scrivi qui'} type={'email'} name={'email'}
                            error={ errors2.email ?  errors2.email.message : ''} inputStyle={{width: '60%'}} 
                            labelStyle={{textTransform: 'uppercase', marginTop: 20}}
                            offset={0} span={12} labelName={'Email'}
                        />
                        <TextBox ref={register2({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'comments'}
                            error={ errors2.comments ?  'Campo richiesto' : ''} inputStyle={{width: '100%'}} 
                            labelStyle={{textTransform: 'uppercase', marginTop: 20}}
                            offset={0} span={12} labelName={'Commento'} textarea
                        />
                        <Row style={{marginTop: '2.5rem', paddingLeft: 20}}><Col>
                            <Button >Invia</Button>
                        </Col></Row>
                    </Form>
                </Card>
            </Col>
        </Row>
        </div>
        <Row style={{height: 400}}>
            <Col>
                <OrangeBoxDot className={classes.orangeBoxDot} />
            </Col>
        </Row>
    </>)
}

export default Contact