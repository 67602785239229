import { clientCredentialAxios } from '../AxiosInstance';


export const getToken = (data) => {
    return clientCredentialAxios.post('auth/login',data);
}

export const registration = (data) => {
    return clientCredentialAxios.post('/auth/register',data);
}

export const resetPassword = (data) => {
    return clientCredentialAxios.post(`/auth/reset-request`,data);
}

export const contractSuggestion = (data) => {
    return clientCredentialAxios.post('/suggestion_contracts', data);
}

export const sendContact = (data) => {
    return clientCredentialAxios.post('/contact',data);
}

export const getFaqs = () => {
    return clientCredentialAxios.get('/faqs');
}

export const getContractCategory = () => {
    return clientCredentialAxios.get('/contract_categories')
}

export const getContracts = () => {
    return clientCredentialAxios.get('/contracts');
}

export const getContract = (id) => {
    return clientCredentialAxios.get(`/contract/${id}`);
}
