import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import LoadingButton from '../../Components/Commons/LoadingButton/LoadingButton';
import TextBox from '../../Components/Commons/FormComponent/TextBox';
import Button from '../../Components/Commons/FormComponent/Button';
import { useForm } from 'react-hook-form';
import Form  from 'react-bootstrap/Form';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
//----------------- Style -------------------
import classes from './ResetPassword.module.css';

const ResetPassword = () => {
    const {token} = useParams();
    const [errorApi, setErrorApi] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);

    const passwordValidator = (value) => value.trim().match(/^[^\s]{4,20}$/) || 'Password non valida: no spazi, min 8 caratteri, massimo 20 caratteri';
    const passwordConfirmValidator = value => value === watch('password') || 'Le password non sono uguali';
    
    const onClickResetPassword = (data) => {
        console.log(data, token)
    }

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    return(<>
        <Row style={{background: 'linear-gradient(#0D2E3D, #0B2430)', paddingBottom: 30, textAlign: "center"}}>
            <Col style={{minHeight: 200, paddingLeft: 60, paddingTop: 30}}>
                <Row><Col className='text-left' ><h1 className={classes.headerTitle}>Resetta la password</h1></Col></Row>
            </Col>
        </Row>
        <Form onSubmit={handleSubmit(onClickResetPassword)} style={{marginTop: '4rem'}}>
            <TextBox ref={register({ validate: passwordValidator })} placeHolder={'password'} type={'password'} name={'password'}
                error={ errors.password ? errors.password.message : ''} inputStyle={{width: '100%'}} offset={4} span={4}
            />
            <TextBox ref={register({ validate: passwordConfirmValidator } )} placeHolder={'conferma password'} type={'password'} name={'password_confirmation'}
                error={ errors.password_confirmation ? errors.password_confirmation.message  : ''} inputStyle={{width: '100%'}} offset={4} span={4}
            />
                    
            { errorApi ? 
                <Row style={{marginTop: '1rem'}}><Col><span className='text-danger'>
                    Token non corretto
                </span></Col></Row>
            : null}
            { loadingApi ? <LoadingButton /> : 
                <Row style={{marginTop: '2.5rem'}}><Col className='text-center'>
                    <Button >
                        Invia
                    </Button>
            </Col></Row> }
        </Form>
    </>)


}

export default ResetPassword;