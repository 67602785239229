import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './Contracts.module.css';
import {useSelector } from 'react-redux';

const Contracts = () => {
    const categoryContract = useSelector(state => state.categoryContract.categoryContract);

    return (
        <>
        <Row style={{background: 'linear-gradient(#0D2E3D, #0B2430)', paddingBottom: 30, textAlign: "center"}}>
             <Col style={{minHeight: 200, paddingLeft: 60, paddingTop: 30}}>
                <Row style={{height: '60%'}}><Col className='text-left' ><span className={classes.headerTitle}>TIPOLOGIE CONTRATTO</span></Col></Row>
                <Row >
                    <Col className='text-left' lg={6}>
                        <Row >
                        { categoryContract.map(category => {
                            return(
                                <Col xs={12} md={6}>
                                    <span className={classes.categoryHeader}>{category.name}</span>
                                </Col>
                            )
                        })}
                        </Row>
                    </Col>
                </Row>
             </Col>
         </Row>
         <Row style={{marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem'}}>
            <Col>
                { categoryContract.map( category => {
                    return( <>
                        <Row>
                            <Col><span className={classes.categoryListName}>{category.name}</span></Col>
                        </Row>
                        <Row>
                            <Col style={{lineHeight: 1}}><span className={classes.categoryListDescription}>Spiegazione Per informazioni riservate si intendono ad esempio know-how, tecnologie, idee brevettabili, rapporti finanziari o commerciali, business plan,piani di sviluppo commerciale, studi, rapporti, etc e che hanno un loro valore economico o che se diffuse possono danneggiare la parte che le ha comunicate.</span></Col>
                        </Row>
                        {category.contracts.map(contract => {
                            return(
                                <Row>
                                    <Col xs={8} md={9} lg={10}><span className={classes.contractListName}>{contract.name}</span></Col>
                                    <Col><span className={classes.contractListName}>prezzo / tempo</span></Col>
                                </Row>
                            )
                        })}
                    </> )
                })} 
            </Col>
         </Row>
        </>
    )
}

export default Contracts;