import React, { Children } from 'react'
import Button from 'react-bootstrap/Button';
import classes from './FormComponent.module.css';

const ButtonCommon = ({name, onClickButton, disabled, children, style, boxShadow}) => {
    return(
        <>
            <Button className={boxShadow ? classes.buttonBoxShadow : null} style={style} type='submit' onClick={onClickButton} disabled={disabled}>{children}</Button>
        </>
    )
}

export default ButtonCommon