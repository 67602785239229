import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import classes from './Ranking.module.css'
import {Stars} from '../../../icons';


const Ranking = () => {
    const [rankings, setRenkings] = useState([
        {title: 'contratto di riservatezza', name: 'Carlo Verdi', work: 'commerciale', description: 'Esposizione chiara e sintetica con indicazioni operative', picture: 'https://api.manciomarket.com/img/products/1588942588.jpeg'},
        {title: 'contratto di riservatezza', name: 'Carlo Verdi', work: 'commerciale', description: 'Esposizione chiara e sintetica con indicazioni operative', picture: 'https://api.manciomarket.com/img/products/1588942588.jpeg'},
        {title: 'contratto di riservatezza', name: 'Carlo Verdi', work: 'commerciale', description: 'Esposizione chiara e sintetica con indicazioni operative', picture: 'https://api.manciomarket.com/img/products/1588942588.jpeg'}
    ])

    return(<>
        <Row style={{marginTop: '4rem'}}>
            <Col className='text-center'><span className={classes.titleStyle}>Cosa dicono di noi ?</span></Col>
        </Row>
        <Row style={{marginTop: '2rem'}}>
                {rankings.map(ranking => {
                    return(
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={5}><Image className={classes.imageStyle} src={ranking.picture} /></Col>
                                <Col style={{lineHeight: 1}} className='my-auto'>
                                    <Row ><Col style={{lineHeight: 1}}><span className={classes.contractStyle}>{ranking.title}</span></Col></Row>
                                    <Row><Col><span className={classes.generalTextStyle}>{ranking.name} - {ranking.work}</span></Col></Row>
                                    <Row><Col><span className={classes.generalTextStyle}>{ranking.description}</span></Col></Row>
                                    <Row><Col><Stars /><Stars /><Stars /><Stars /><Stars /></Col></Row>
                                </Col>
                            </Row>
                        </Col>
                    )
                })}
        </Row>
    </>)
}


export default Ranking;