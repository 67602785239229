import React, {useState, useEffect} from 'react';
//------------ Bootstrap ----------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//------------ Components ---------------
import LoadingButton from '../../Components/Commons/LoadingButton/LoadingButton';
//------------ Style ------------------
import classes from './Faq.module.css';
import {ChevronUp, ChevronDown} from '../../icons';
//------------- Others ---------------
import * as clientCredentialApi from '../../Axios/Call/ClientCredential';

const Faq = () => {
    const [loadingFaq, setLoadingFaq] = useState(false);
    const [faqs,setFaqs] = useState([]);
    const [choosenFaq, setChoosenFaq] = useState(0);
    const [errorApi, setErrorApi] = useState(false);

    useEffect(() => {
        setLoadingFaq(true);
        clientCredentialApi.getFaqs().then(response => {
            setFaqs(response.data)
            setLoadingFaq(false);
        }).catch(error => {
            setErrorApi(true);
            setLoadingFaq(false);
        })
    },[]);
    const openFaqHandler = (faqId) => {
        setChoosenFaq(choosenFaq == faqId ? 0 : faqId);
    }
    return(<>
         <Row style={{background: 'linear-gradient(#0D2E3D, #0B2430)', paddingBottom: 30, textAlign: "center"}}>
             <Col style={{minHeight: 200, paddingLeft: 60, paddingTop: 30}}>
                <Row><Col className='text-left' ><h1 className={classes.headerTitle}>FAQ</h1></Col></Row>
                <Row><Col className='text-left'><span className={classes.smallTitle}>DOMANDE FREQUENTI</span></Col></Row>
             </Col>
         </Row>
         <Row style={{marginTop: '4rem'}}>
             <Col>
                {loadingFaq && !faqs.length? <LoadingButton /> :
                  faqs.map(faq => {
                    return( <>
                        <Row>
                            <Col xs={11} style={{paddingLeft: 100, paddingRight: 20, paddingBottom: 10}}>
                                <span className={classes.questionText}>Question: {faq.question}</span>
                            </Col>
                            <Col xs={1}>
                                { faq.sort == choosenFaq ? 
                                    <ChevronUp onClick={ () => openFaqHandler(faq.sort) }/>
                                : <ChevronDown onClick={ () => openFaqHandler(faq.sort) }/>
                                }
                            </Col>
                        </Row>
                        { faq.sort == choosenFaq ? 
                            <Row><Col style={{paddingLeft: 100, paddingRight: 20, paddingBottom: 30}}>
                                <span className={classes.answerText}>answer: {faq.answer}</span></Col></Row>
                        : null }
                    </>)
                })}
             </Col>
         </Row>
    </>)
}

export default Faq;