import React, {useState} from 'react';
//-------------- Bootstrap ----------------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//--------------- Component ----------------------
import TextBox from '../../Components/Commons/FormComponent/TextBox';
import Button from '../../Components/Commons/FormComponent/Button';
import Ranking from '../../Components/Commons/Ranking/Ranking';
import LoadingButton from '../../Components/Commons/LoadingButton/LoadingButton';
import ModalSimple from '../../Components/Commons/ModalSimple/ModalSimple';
//---------------- Others --------------
import { useForm } from 'react-hook-form';
import * as clientCredentailApi from '../../Axios/Call/ClientCredential';
import {Link, useHistory} from 'react-router-dom';
//---------------- Style ---------------
import classes from './Registration.module.css';

const Registration = () => {
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [stepErrors, setStepErrors] = useState([]);
    const history = useHistory();

    const emailValidator = (value) => value.trim().match(/^.+@.+$/) || 'Email non valida';
    const passwordValidator = (value) => value.trim().match(/^[^\s]{8,20}$/) || 'Password non valida: no spazi, min 8 caratteri, massimo 20 caratteri';
    const passwordConfirmValidator = (value) => value === watch('password') || 'Le Password non corrispondono';

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const onClickLoginHandler = (data) => {
        setLoadingApi(true);
        clientCredentailApi.registration(data).then(response => {
            setLoadingApi(false);
            setRegistrationSuccess(true);
        }).catch(error => {
            setLoadingApi(false);
            if(error.response && error.response.status === 400){
                const {response: {data: stepErrors}} = error;
                for (const [key, value] of Object.entries(stepErrors))
                    stepErrors[key] = {message: Array.isArray(value) ? value.join('. ') : value};
                setStepErrors(stepErrors);
            }else{
                setErrorApi(true);
            }
        })
    }

    const onHideCustomeHandler = () => {
        history.push('/login');
    }
    return(<>
        {registrationSuccess ? <ModalSimple title={'Accont creato'} description="L' account e' stato creato con successo puoi effettuare il login" 
                onHideCustomeHandler={onHideCustomeHandler}
        /> : null}
        <Row style={{background: 'linear-gradient(#0D2E3D, #0B2430', paddingBottom: 30, textAlign: "center"}}>
            <Col>
                <Row style={{marginTop: '2rem'}}>
                    <Col><span className={classes.headerText}>Inserisci la tua email per iniziare</span></Col>
                </Row>
                <Form onSubmit={handleSubmit(onClickLoginHandler)} >
                    <TextBox ref={register({ validate: emailValidator })} placeHolder={'email'} type={'email'} name={'email'}
                        error={ errors.email ?  errors.email.message : ''} inputStyle={{width: '100%'}} offset={4} span={4}
                    />
                    <TextBox ref={register({ validate: passwordValidator })} placeHolder={'scegli la tua password'} type={'password'} name={'password'}
                        error={ errors.password ?  errors.password.message : stepErrors.email ? "La mail e' gia in uso" : ''} inputStyle={{width: '100%'}} offset={4} span={4}
                    />
                    <TextBox ref={register({ validate: passwordConfirmValidator })} placeHolder={'ripeti la tua password'} type={'password'} name={'password_confirmation'}
                        error={ errors.password_confirmation ?  errors.password_confirmation.message : ''} inputStyle={{width: '100%'}} offset={4} span={4}
                    />
                    { errorApi ? <Row style={{marginTop: '1rem'}}><Col><span className='text-danger'>Errore nella registrazione</span></Col></Row>: null}
                    <Row style={{marginTop: '2.5rem'}}><Col>
                        { loadingApi ? <LoadingButton /> : 
                        <Button >Inizia ora</Button> }
                    </Col></Row>
                    <Row style={{marginTop: '0.5rem'}}><Col>
                        <span className={classes.textStyle}>Sei gia' iscritto? vai al  </span> 
                        <Link to='/login' ><span className={classes.forgotPasswordStyle}>LOG IN</span></Link>
                    </Col></Row>
                </Form>
            </Col>
        </Row>
        <Ranking />
    </>)
}


export default Registration;