import React, {useState, useEffect} from 'react';
//----------------- Bootstrap ------------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//----------------- Components -------------------
import TextBox from '../../Components/Commons/FormComponent/TextBox';
import Button from '../../Components/Commons/FormComponent/Button';
import Ranking from '../../Components/Commons/Ranking/Ranking'
import LoadingButton from '../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../Components/Commons/AlertGeneral/AlertGeneral';
//----------------- Axios ---------------------
import * as clientCredentailApi from '../../Axios/Call/ClientCredential';
import {authApiAxios} from '../../Axios/AxiosInstance';
//---------------- Others ---------------------
import { useForm } from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actionCreator from '../../store/action/index';
//----------------- Style -------------------
import classes from './Login.module.css';

const Login = () => {
    const [errorApi, setErrorApi] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [successForgotPassword,setSuccessForgotPassword] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const userData = useSelector(state => state.user.userData);

    const emailValidator = (value) => value.trim().match(/^.+@.+$/) || 'Email non valida';
    const passwordValidator = (value) => value.trim().match(/^[^\s]{4,20}$/) || 'Password non valida: no spazi, min 8 caratteri, massimo 20 caratteri';


    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    useEffect(() => {
        if(Object.keys(userData).length){
            history.push('/profilo');
        }
    },[userData])

    const onClickLoginHandler = (data) => {
        setLoadingApi(true);
        if(!forgotPassword){
            clientCredentailApi.getToken(data).then(response => {
                localStorage.setItem("token", 'Bearer ' + response.data.token);
                authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
                dispatch(actionCreator.storeUserData());
                setLoadingApi(false);
            }).catch(error =>{
                setErrorApi(true);
                setLoadingApi(false);
            })
        }else{
            clientCredentailApi.resetPassword({email: data.email}).then(response => {
                setLoadingApi(false);
                successForgotPassword(true);
                setForgotPassword(false);
            }).catch(error => {
                setErrorApi(true);
                setLoadingApi(false);
            })
        }
    }

    const onClickForgotPassword = () => {
        setForgotPassword(!forgotPassword);
    }

    return(<>
        { successForgotPassword ? <AlertGeneral setTrigger={setSuccessForgotPassword} closeAlone description="Ti e' arrivata una email per impostare la nuova password" /> : null}
        <Row style={{background: 'linear-gradient(#0D2E3D, #0B2430', paddingBottom: 30, textAlign: "center"}}>
            <Col>
                <Row style={{marginTop: '2rem'}}>
                    <Col><span className={classes.headerText}>{!forgotPassword ? 'Area personale' : 'Recupera Password' }</span></Col>
                </Row>
                <Form onSubmit={handleSubmit(onClickLoginHandler)} >
                    <TextBox ref={register({ required: 'true' })} placeHolder={'email'} type={'input'} name={!forgotPassword ? 'username' : 'email'}
                        error={ errors.username ? 'Campo richiesto'  : ''} inputStyle={{width: '100%'}} offset={4} span={4}
                    />
                    {!forgotPassword ? 
                        <TextBox ref={register({ validate: passwordValidator })} placeHolder={'password'} type={'password'} name={'password'}
                            error={ errors.password ?  errors.password.message : ''} inputStyle={{width: '100%'}} offset={4} span={4}
                        />
                    : null }
                        <Row style={{marginTop: '0.5rem'}}>
                            <Col>
                                <span className={classes.forgotPasswordStyle}  onClick={() => onClickForgotPassword()}>
                                    {!forgotPassword ? 'password dimenticata?' : 'torna al login' }
                                </span>
                            </Col>
                        </Row>
                    { errorApi ? <Row style={{marginTop: '1rem'}}><Col><span className='text-danger'>
                        {!forgotPassword ? 'Email o password errata' : 'La email non esiste' }
                    </span></Col></Row>: null}
                    { loadingApi ? <LoadingButton /> : 
                        <Row style={{marginTop: '2.5rem'}}><Col>
                            <Button >
                                {!forgotPassword ? 'Log in' : 'Invia' }
                            </Button>
                        </Col></Row>
                    }
                    <Row style={{marginTop: '0.5rem'}}><Col>
                        <span className={classes.textStyle}>Non sei ancora iscritto?  </span> 
                        <Link to='/registrazione' ><span className={classes.forgotPasswordStyle}>Inizia ora</span></Link>
                    </Col></Row>
                </Form>
            </Col>
        </Row>
        <Ranking />
    </>)
}


export default Login;