import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {PdfIcon} from '../../icons';
import classes from './PastBilling.module.css'

const PastBilling = () => {
    const [pastBilling, setPastBilling] = useState([{id:1, contract: 'Atto A', data: '16/17/20', price: '14,99'},
    {id:2, contract: 'Atto A', data: '16/17/20', price: '14,99'},{id:1, contract: 'Atto A', data: '16/17/20', price: '14,99'},
    {id:3, contract: 'Atto A', data: '16/17/20', price: '14,99'}]);

    return(<>
        <Row>
            <Col>
                <Row>
                    <Col><span className={classes.headerTable}>Acquisto</span></Col>
                    <Col><span className={classes.headerTable}>Data</span></Col>
                    <Col><span className={classes.headerTable}>Importo</span></Col>
                    <Col><span className={classes.headerTable}>Scarica Fattura</span></Col>
                </Row>
                    {pastBilling.map(billing => {
                        return(
                            <Row style={{marginTop: 15}}>
                                <Col><span className={classes.valueTable} style={{paddingLeft: 7}}>{billing.contract}</span></Col>
                                <Col><span className={classes.valueTable} >{billing.data}</span></Col>
                                <Col><span className={classes.valueTable} style={{paddingLeft: 12}}>{billing.price}</span></Col>
                                <Col ><PdfIcon style={{marginLeft: 50}} /></Col>
                            </Row>
                        )
                    })}
                </Col>
        </Row>
    </>)
}

export default PastBilling;