import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './HomePage.module.css';
import Button from '../Commons/FormComponent/Button';
import {ChevronRight} from '../../icons';
import {Link} from 'react-router-dom';

const ContractCard = (props) => {
    return(<>
        <Card style={{backgroundColor: '#0D2E3D', padding: 20, width: '90%'}} >
            <Row>
                <Col><span className={classes.contractTitle}>{props.contract.name}</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col style={{lineHeight: 1}}>
                    <span className={classes.contractDescription}>{props.contract.description}</span>
                </Col>
            </Row>
            <Row style={{marginTop: '3rem'}}>
                <Col className='text-center'>
                    <Button style={{width: 150, height: 35}}>
                        <Link to={`contratto/${props.contract.id}`}>
                        <Row><Col xs={9} className='text-right'>
                            <span className={classes.buttonText}>Dettagli </span>
                        </Col>
                        <Col xs={3} className='my-auto'>
                            <ChevronRight style={{paddingTop: 5}}/>
                        </Col>
                        </Row>
                        </Link>
                    </Button>
                </Col>
            </Row>
        </Card>
    </>)
}

export default ContractCard;