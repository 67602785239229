import React,{useEffect, useState} from 'react';
import Ranking from '../../Components/Commons/Ranking/Ranking';
import FirstSession from '../../Components/HomePage/FirstSession';
import SecondSession from '../../Components/HomePage/SecondSession';
import * as clientCredentialApi from '../../Axios/Call/ClientCredential';

const HomePage = () => {
    const [loadingApi, setLoadingApi] =  useState(false);
    const [errorApi, setErrorApi] = useState(false);
    const [contracts, setContract] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoadingApi(true);
        clientCredentialApi.getContracts().then(response => {
            setContract(response.data);
            setLoadingApi(false);
        }).catch(error => {
            setLoadingApi(false);
        })
    },[])

    return(<> 
        <div style={{paddingLeft: 40, paddingRight: 40}}>
            <FirstSession contracts={contracts.slice(0, 2)} loadingApi={loadingApi} errorApi={errorApi}/>
            <Ranking />
            <SecondSession contracts={contracts.slice(2, 8)} loadingApi={loadingApi} errorApi={errorApi}/>
        </div>
    </>)
}

export default HomePage;