import React, {useState} from 'react';
//------------- Bootstrap ------------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//------------- Components -----------------
import ContractCard from './ContractCard';
import TextBox from '../Commons/FormComponent/TextBox';
import Button from '../Commons/FormComponent/Button';
import LoadingButton from '../Commons/LoadingButton/LoadingButton';
//------------- Styles -------------------
import {FacebookIcon} from '../../icons';
import classes from './HomePage.module.css'
import {GirlWriting, OrangeBox, ChevronRight, Heands, Layout, Contract, Price, Time, Doc} from '../../icons';
//-------------- Others ---------------------
import {Link} from 'react-router-dom';

const FirstSession = (props) => {
    const [features, setFeatures] = useState([{name: 'Soddisfatto o rimborsato', icon: 'heand'}, {name: 'Soddisfatto o rimborsato', icon: 'layout'},
    {name: 'Soddisfatto o rimborsato', icon: 'contract'},{name: 'Soddisfatto o rimborsato', icon: 'price'}, {name: 'Soddisfatto o rimborsato', icon: 'time'},
    {name: 'Soddisfatto o rimborsato', icon: 'doc'}])
    
    
    const getIcons = (type) => {
        switch(type){
            case 'heand':
                return <Heands /> 
            case 'layout':
                return <Layout />
            case 'contract':
                return <Contract />
            case 'price':
                return <Price /> 
            case 'time':
                return <Time />
            case 'doc':
                return <Doc />
        }
    }
    return(<>
        <Row>
            <Col xs={10} xl={7}>
                <OrangeBox style={{position: 'absolute', zIndex: 1, right: '80%', top:'10%', width:"371.896", height:"371.896"}}/>
                <Row><Col><span className={classes.headerText}>Affidabile e veloce</span></Col></Row>
                <Row style={{width: '100%'}}><Col style={{marginTop: 4, lineHeight: 1.2, zIndex: 10}} >
                    <span className={classes.subHeaderText}>Affidati ai professionisti e crea il contratto su misura in pochi minuti</span>
                </Col></Row>
                <Row><Col style={{zIndex: 10}}>
                    <TextBox placeHolder={'Cosa stai cercando'} type={'input'} name={'act'}
                        inputStyle={{width: '90%' }} offset={0} span={12} labelStyle={{textTransform: 'uppercase'}}
                    />
                </Col></Row>
                <Row style={{marginTop: '2rem'}} >
                { props.loadingApi ? <LoadingButton /> :
                    props.contracts.map(contract => {
                    return(
                        <Col xs={12} md={6} style={{zIndex: 10, marginTop: 10}} className={classes.padding0}>
                            <ContractCard key={contract.id} contract={contract} />
                        </Col>
                    )
                })}
                </Row>
            </Col>
            <Col xs={2} xl={2}>
                <div >
                    <OrangeBox className={classes.orangeBoxStyle} width="371.896" height="371.896"/>
                    <GirlWriting className={classes.girlStyle}/>
                </div>
            </Col>
            <Col xs ={12} xl={3}>
                <Row style={{marginTop: '1rem'}}>
                    {features.map(feature => {
                        return(
                            <>
                                <Col xs={6} md={4} xl={6} >
                                    <Card className={classes.featureCardStyle}>
                                        <Row><Col className='text-center'>{getIcons(feature.icon)}</Col></Row>
                                        <Row><Col className='text-center' style={{marginTop: 4, lineHeight: 1}}><span className={classes.featureText}>{feature.name}</span></Col></Row>
                                    </Card>
                                </Col>
                            </>
                        )
                    })}
                </Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col className='text-center'>
                        <Button boxShadow >
                            <Link to='contratti'>
                            <Row><Col xs={9} className='text-right my-auto'>
                                <span className={classes.buttonTextStart}>Inizia Ora</span>
                            </Col>
                            <Col xs={3} className='my-auto'>
                                <ChevronRight style={{paddingTop: 10}}/>
                            </Col>
                            </Row>
                            </Link>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}

export default FirstSession;