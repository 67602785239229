import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import TextBox from '../../Components/Commons/FormComponent/TextBox';
import Button from '../../Components/Commons/FormComponent/Button';
import LoadingButton from '../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../Components/Commons/AlertGeneral/AlertGeneral';
import classes from './Billing.module.css';
import { useForm } from 'react-hook-form';
import {useSelector} from 'react-redux';
import * as authApi from '../../Axios/Call/AuthApi';

const Billing = () => {
    const [errorApi, setErrorApi] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [successApi, setSuccessApi] = useState(false);
    const userData = useSelector(state => state.user.userData);

    useEffect(() => {
        if (userData && Object.keys(userData).length) {
            reset(userData);
        }
	},[ userData ]);

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
        defaultValues: {...userData}
    }); // initialise the hook

    const onClickChangeBilling = (data) => {
        setLoadingApi(true);
        authApi.updateProfile(data, userData.id).then(response => {
            setLoadingApi(false);
            setSuccessApi(true);
        }).catch(error => {
            setLoadingApi(false);
            setErrorApi(true);
        })
    }

    return(<>
    {errorApi ? <AlertGeneral description='errore nel form' setTrigger={setErrorApi} closeAlone color='danger' /> : null }
        {successApi ? <AlertGeneral description='dati cambiati con successo' setTrigger={setSuccessApi} closeAlone color='success' /> : null }
    <Row>
        <Col>
            <Form onSubmit={handleSubmit(onClickChangeBilling)} >
                <Row>
                    <Col><span className={classes.formTextHeader}>INDIRIZZO DI FATTURAZIONE</span></Col>
                </Row>
                <Row style={{marginTop: 5}}>
                    <Col xs={12} md={6}>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'address1'}
                            error={ errors.address1 ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={'Indirizzo 1'}
                            labelStyle={{ color: 'black'}}
                        />
                    </Col>
                    <Col>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'address2'}
                            error={ errors.address2 ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={'Indirizzo 2'}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 5}}>
                    <Col xs={12} md={6}>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'city'}
                            error={ errors.city ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={"Citta'"}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                    <Col>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'province'}
                            error={ errors.province ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={"Provincia"}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '4.5rem' }}>
                    <Col className='text-center'>
                        { loadingApi ? <LoadingButton /> :
                            <Button >Salva</Button>
                        }
                    </Col>
                </Row>
            </Form>
        </Col>
    </Row>
    </>)
}

export default Billing;