import * as actionTypes from '../action/actionsTypes';
import {updateObject} from '../utility';

const initialState = {
    categoryContract: [],
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.POPULATE_CATEGORY_CONTRACT:
            return updateObject(state, {categoryContract: action.categoryContract})
    }
    return state;
}

export default reducer