import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import classes from './Layout.module.css'
import {Link, useLocation} from 'react-router-dom';

const LayoutUser = (props) => {
    const [fields, setFields] = useState([{name: 'Dati profilo',link:'/profilo'},
    {name: 'Metodo di pagamento',link:'/pagamento'},{name: 'Ripilogo pagamenti',link:'/riepilogo-pagamenti'}
    ,{name: 'Aggiungi logo/intestazioni',link:'/logo'}, {name: 'Archivio logi/intestazioni',link:'/archivio-loghi'},
    {name: 'Segnala un problema',link:'/segnala-problema'}])

    const location = useLocation();

    const getCurrentPageColor = (link) => {
         if(location.pathname == link) {
                return classes.menuColor;
         }

         return null
    }

    return(<> 
        <Row style={{background: 'linear-gradient(#0D2E3D, #0B2430)', paddingBottom: 30, textAlign: "center"}}>
             <Col style={{minHeight: 200, paddingLeft: 60, paddingTop: 30}}>
                <Row style={{height: '90%'}}><Col>
                    <Row><Col className='text-left' ><span className={classes.headerText}>Felice di vederti</span></Col></Row>
                    <Row><Col className='text-left'><span className={classes.nameText}>Mario Rossi</span></Col></Row>
                </Col></Row>
                <Row className={classes.rowStyle}>
                    <Col className='text-left'>
                        <Link to='/i-miei-atti' ><span className={location.pathname == 'i-miei-atti' ? classes.selectedHeaderType  :classes.headerType}>I MIEI ATTI</span></Link>
                    </Col>
                    <Col className='text-left'>
                        <Link to='/profilo' ><span className={location.pathname != 'i-miei-atti' ? classes.selectedHeaderType  :classes.headerType}>IL TUO PROFILO</span></Link>
                    </Col>
                </Row>
             </Col>
        </Row>
        <Row style={{marginTop: '2rem'}}>
            {props.noMenu ? null :
                <Col xs={12} lg={4}>
                    <Row style={{marginLeft: 20, marginRight: 20, background: 'linear-gradient(#0D2E3D, #0B2430)'}}>
                        <Col>
                            <Row >
                            {fields.map(field => {
                                return(
                                        <Col xs={12} md={6} lg={12} className={getCurrentPageColor(field.link)} style={{padding: 12, borderTop: field.link == 'segnala-problema' ? '1px solid grey' : ''}}>
                                            <Link to={field.link}><span className={classes.menuText}>{field.name}</span></Link>
                                        </Col>
                                )
                            })}
                            </Row>
                        </Col>
                    </Row>
                </Col> 
            }
            <Col className={classes.colMain}>
                {props.children}
            </Col>
        </Row>
    </>)
}

export default LayoutUser;