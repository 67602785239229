import {authApiAxios} from '../AxiosInstance';

//------------------------------ User Api Call -------------------
export const getUserData = () => {
    return authApiAxios.get('/auth/me');
}


export const updateProfile = (data, id) => {
    return authApiAxios.patch(`/users/${id}`,data);
}
