import React , {forwardRef} from 'react'
import Form from 'react-bootstrap/Form';
import classes from './FormComponent.module.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const TextBox = forwardRef(({value, name, setValue, placeHolder, labelName,labelStyle, error, textarea, type, inputStyle, offset, span
    ,control}, ref) => {
    return( <>
        <Row>
            <Col >
                <Form.Label className={classes.label} style={labelStyle} >{labelName}</Form.Label>
            </Col>
        </Row>
        <Row >
            <Col md={{ span: span, offset: offset }}>
                <Form.Control type={type} as={textarea ? 'textarea' : 'input'} rows={8} name={name}
                    placeholder = {placeHolder}
                    className={classes.inputStyle}
                    style={inputStyle}
                    ref={ref}
                />
            </Col>
        </Row>
        {error && ( <small className="text-danger small">{error}</small>)}
    </>
    )
})

export default TextBox;