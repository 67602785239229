import * as actionsTypes from './actionsTypes';
import * as clientCredentialApi from '../../Axios/Call/ClientCredential';


export const storeCategoryContract = () => {
    return(dispatch,state) => {
        clientCredentialApi.getContractCategory().then(response => {
            const categoryContract = response.data
            dispatch(saveCategoryContract(categoryContract));
        }).catch( error => {
            console.log(error);
        })
    }
}

const saveCategoryContract = (categoryContract) => {
    return{
        type: actionsTypes.POPULATE_CATEGORY_CONTRACT,
        categoryContract: categoryContract
    }
}