import React, {useEffect, useState} from 'react';
//---------------- Bootstrap ---------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//---------------- Components -----------------
import TextBox from '../../Components/Commons/FormComponent/TextBox';
import Button from '../../Components/Commons/FormComponent/Button';
import LoadingButton from '../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../Components/Commons/AlertGeneral/AlertGeneral';
//---------------- Others ----------------------
import classes from './Profile.module.css';
import { useForm } from 'react-hook-form';
import {useSelector} from 'react-redux';
import * as authApi from '../../Axios/Call/AuthApi';

const Profile = () => {
    const [errorApi, setErrorApi] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [successApi, setSuccessApi] = useState(false);

    const userData = useSelector(state => state.user.userData);
    const { control, register, handleSubmit, errors, watch, reset } = useForm({
        defaultValues: {...userData}
    }); // initialise the hook

    useEffect(() => {
        if (userData && Object.keys(userData).length) {
            reset(userData);
        }
	},[ userData ]);

    const onClickChangeProfileHandler = (data) => {
        setLoadingApi(true);
        authApi.updateProfile(data, userData.id).then(response => {
            setLoadingApi(false);
            setSuccessApi(true);
        }).catch(error => {
            setLoadingApi(false);
            setErrorApi(true);
        })
    }

    return(<>
        {errorApi ? <AlertGeneral setTrigger={setErrorApi} description='errore nel form' closeAlone color='danger' /> : null }
        {successApi ? <AlertGeneral setTrigger={setSuccessApi} description='dati cambiati con successo' closeAlone color='success' /> : null }
        <Row>
            <Col>
            <Form onSubmit={handleSubmit(onClickChangeProfileHandler)} >
                <Row>
                    <Col><span className={classes.formTextHeader}>DATI PERSONALI</span></Col>
                </Row>
                <Row style={{marginTop: 5}}>
                    <Col xs={12} md={6}>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'firstName'}
                            error={ errors.firstName ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={'Nome'}
                            labelStyle={{ color: 'black'}}
                        />
                    </Col>
                    <Col>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'lastName'}
                            error={ errors.lastName ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={'Cognome'}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 5}}>
                    <Col xs={12} md={6}>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'fiscalCode'}
                            error={ errors.fiscalCode ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={'Codice fiscale'}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                    <Col>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'addressCity'}
                            error={ errors.addressCity ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={"Citta'"}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 5}}>
                    <Col>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'vatNumber'}
                            error={ errors.vatNumber ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={'P.IVA'}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col><span className={classes.formTextHeader}>DATI ACCOUNT</span></Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col xs={12} md={6}>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'username'}
                            error={ errors.username ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={'Username'}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                    <Col>
                        <TextBox ref={register()} placeHolder={'Scrivi qui'} type={'password'} name={'password'}
                            error={ errors.password ?  'Campo richiesto' : ''} inputStyle={{width: '90%'}} offset={0} span={12} labelName={'Password'}
                            labelStyle={{color: 'black'}}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '4.5rem' }}>
                    <Col className='text-center'>
                        {   loadingApi ? <LoadingButton /> :
                            <Button >Salva</Button>
                        }
                    </Col>
                </Row>
            </Form>
            </Col>
        </Row>
    </>)
}

export default Profile;