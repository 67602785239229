import React, {useState} from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ImageUploader from 'react-images-upload';
import Button from '../../Components/Commons/FormComponent/Button';

const UploadLogo = () => {
    const [base64, setBase64] = useState('');
    const [nameLogo, setNameLogo] = useState('');
    const [overviewLogo, setOverviewLogo] = useState('');

    const onUploadProvisoryPictureProduct = (picture) => {
        const file = picture[0];
		if(file){
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setBase64(reader.result);
			};
		}
    }

    const onChangeNameLogo = (text) => {
        setNameLogo(text);
    }

    return(<>
        <Row>
            <Col>
                <ImageUploader
                    withIcon={false}
                    buttonText="Scegli foto"
                    onChange={(event) => onUploadProvisoryPictureProduct(event)}
                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                    fileTypeError={'File non supportato'}
                    fileSizeError={'File troppo grande'}
                    maxFileSize={5242880}
                    buttonStyles={{backgroundColor: '#FF833E'}}
                    labelStyles={{fontSize: 12, fontFamily: 'Montserrat'}}
                    style={{marginTop: -40}}
                    label="jpg, png, jpeg"
                />
            </Col>
            <Col>
                <Row><Col><span style={{fontSize: 14}}>Salva come</span></Col></Row>
                <Row style={{marginTop: 2}}><Col>
                    <Form.Control type='input' name={'name'}
                        placeholder = {'nome logo'}
                        value={nameLogo}
                        onChange={(event) => onChangeNameLogo(event.target.value)}
                        style={{fontSize: 14}}
                    />
                </Col></Row>
            </Col>
            <Col>
                <Row><Col><span>Anteprima</span></Col></Row>
                { overviewLogo ? 
                    <Row><Col><Image  style={{width: '90%'}} src={overviewLogo} /></Col></Row>
                : null }
            </Col>
        </Row>
        <Row style={{marginTop: '4rem'}}>
            <Col className='text-center'>
                <Button>Salva</Button>
            </Col>
        </Row>
    </>)
}


export default UploadLogo;