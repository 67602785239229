import React, {useState, useEffect} from 'react'
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { propTypes } from 'react-bootstrap/esm/Image';

const AlertGeneral = ({color,closeAlone,description,setTrigger}) => {
 
    useEffect( () => {
        if(closeAlone){
            const time = setTimeout(() => setTrigger(false), 3000)
            return() => {
                clearTimeout(time)
            }
        }
    },[])

    return(
        <>
        <Alert style={{zIndex: 10, position: 'fixed', left: '50%', transform: 'translateX(-50%)', width: '50%', marginTop: 10}} variant={color} onClose={() => setTrigger(false)} dismissible>
            <Row>
                <Col className='text-center'>
                    {description}
                </Col>
            </Row>
        </Alert>
        </>
    );
}


export default AlertGeneral;