import React, {useState, useEffect} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card'
import classes from './MyContract.module.css';

const MyContract = () => {
    const [contracts, setContracts] = useState([{id: 1, name: 'ATTO DSSDADASDSADS N 1'},{id: 2, name: 'ATTO N 2'}, {id: 3, name: 'ATTO N 3'},
    {id: 4, name: 'ATTO N 4'},{id: 5, name: 'ATTO N 5'}])
    return(<div style={{padding: 20}}>
        <Row>
            <Col><span className={classes.headerStyle}>
                Per CREARE, MODIFICARE, ESPORTARE e STAMPARE seleziona una delle versioni presenti sulla destra
            </span></Col>
        </Row>
        <Row style={{marginTop: '2rem'}}>
            <Col>
                {contracts.map( contract => {
                    return(
                        <Row style={{marginTop: 20}}>
                            <Col xs={4} >
                                <Card className={classes.boxName}>
                                    <Row><Col className='text-center my-auto' >
                                        <span className={classes.contractName}>{contract.name}</span>
                                    </Col></Row>
                                </Card>
                            </Col>
                            <Col>
                                <Row><Col><span>Visualizza atto</span></Col></Row>
                                <Row><Col><span>Visualizza atto</span></Col></Row>
                                <Row><Col><span>Visualizza atto</span></Col></Row>
                                <Row><Col><span>Visualizza atto</span></Col></Row>
                                <Row><Col><span>Visualizza atto</span></Col></Row>
                                <Row><Col><span>Visualizza atto</span></Col></Row>
                                <Row><Col><span>Visualizza atto</span></Col></Row>
                            </Col>
                        </Row>
                    )
                })}
            </Col>
        </Row>
    </div>)
}

export default MyContract;