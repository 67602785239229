import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './ProblemForm.module.css'
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import TextBox from '../../Components/Commons/FormComponent/TextBox';
import Button from '../../Components/Commons/FormComponent/Button';

const ProblemForm = () => {

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const onClickProblemHandler = (data) => {
        console.log(data);
    }

    return(<>
        <Row>
            <Col><span className={classes.headerForm}>Il nostro impegno nei confronti dei nostri clienti è costante. Per questo ti chiediamo di segnalare qualsiasi problema tu abbia riscontrato. Cercheremo di risolverlo il prima possibile.</span></Col>
        </Row>
        <Row>
            <Col>
            <Form onSubmit={handleSubmit(onClickProblemHandler)} >
                <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'comments'}
                    error={ errors.comments ?  'Campo richiesto' : ''} inputStyle={{width: '100%'}} 
                    labelStyle={{textTransform: 'uppercase', marginTop: 20}}
                    offset={0} span={12} labelName={'Commento'} textarea
                />
                 <Row style={{marginTop: '2.5rem', paddingLeft: 20}}><Col className='text-center'>
                    <Button >Invia</Button>
                </Col></Row>
            </Form>
            </Col>
        </Row>
    </>)
}


export default ProblemForm;